<template>
  <client-only>
    <TransitionRoot :show="isOpen" as="template">
      <Dialog
        component-name="modal-add-to-moodboard"
        data-skin="white"
        class="relative z-50"
        data-lenis-prevent
      >
        <TransitionChild
          enter="transition-opacity duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <!-- The backdrop, rendered as a fixed sibling to the panel container -->
          <div class="modal-backdrop" aria-hidden="true" @click="close()"></div>
        </TransitionChild>

        <!-- Full-screen scrollable container -->
        <div
          class="pointer-events-none overflow-clip fixed inset-0 flex justify-end w-full h-full"
        >
          <!-- Container to center the panel -->
          <div class="flex items-stretch justify-end w-full h-full max-h-full">
            <TransitionChild
              enter="transition-transform duration-300 ease-out delay-200"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <!-- The actual dialog panel -->
              <component
                :is="$device.isMobile ? 'div' : DialogPanel"
                data-skin="white"
                class="pointer-events-auto overflow-auto flex flex-col w-[calc(100vw-60px)] max-w-[700px] h-full max-h-full bg-skin-base p-edge"
              >
                <div
                  v-if="userStore.logged"
                  class="relative top-0 left-0 flex flex-col items-stretch w-full max-w-full gap-3xl"
                >
                  <div class="flex flex-col items-stretch w-full gap-3xl">
                    <txt-heading
                      size="small"
                      :overline="userStore.fullName"
                      :title="$t.labels.add_to_moodboard"
                      :text="
                        userStore.user.moodboards?.length
                          ? $t.texts.add_to_moodboard
                          : $t.texts.add_to_moodboard_from_0
                      "
                      class="w-full"
                    />

                    <div
                      v-if="userStore.user.moodboards?.length"
                      class="flex flex-col w-full"
                    >
                      <divider />
                      <collapse
                        :title="$t.labels.create_moodboard"
                        icons-type="math"
                        ref="tpl_collapse"
                      >
                        <template #header="collapseProps">
                          <btn
                            button-tag="summary"
                            :title="collapseProps.title"
                            class="flex justify-between items-center gap-xl text-skin-base bg-skin-base px-md h-[64px] hover:bg-skin-inverse hover:text-skin-inverse transition-colors duration-300 ease-in-out cursor-pointer"
                          >
                            <wrapper-reveal
                              reveal="fade-from-right"
                              class="grow w-full"
                            >
                              <div
                                class="text-skin-base text-body-big group-hover/btn:text-skin-inverse transition-colors duration-300 ease-in-out"
                                v-html="collapseProps.title"
                              />
                            </wrapper-reveal>
                            <wrapper-reveal
                              reveal="fade-from-0"
                              class="grow-0 flex justify-end min-w-min w-min max-w-min"
                            >
                              <svg-icon
                                :icon="
                                  collapseProps.isOpen
                                    ? collapseProps.icons.opened
                                    : collapseProps.icons.closed
                                "
                              />
                            </wrapper-reveal>
                          </btn>
                        </template>
                        <form-create-moodboard
                          class="w-full"
                          :createdCallback="addItemOnMoodboardCreate"
                        />
                      </collapse>
                      <divider />
                    </div>

                    <wrapper-reveal
                      v-else
                      reveal="fade-from-bottom"
                      class="w-full"
                    >
                      <div class="w-full">
                        <form-create-moodboard
                          class="w-full"
                          :createdCallback="addItemOnMoodboardCreate"
                        />
                      </div>
                    </wrapper-reveal>
                    <ul
                      v-if="userStore.user.moodboards?.length"
                      class="flex flex-col items-stretch w-full list-none gap-2xl"
                    >
                      <li>
                        <wrapper-reveal
                          reveal="fade-from-right"
                          class="flex flex-col gap-2xs"
                        >
                          <div
                            class="text-body-big px-md"
                            v-html="
                              userStore.user.moodboards.length > 1
                                ? $t.labels.add_to_moodboards
                                : $t.labels.add_to_moodboard
                            "
                          ></div>
                        </wrapper-reveal>
                      </li>
                      <!-- {{ userStore.user.moodboards }} -->
                      <li
                        v-for="item in userStore.user.moodboards"
                        :key="item.slug"
                        class="flex flex-col items-stretch w-full gap-lg"
                      >
                        <card-add-to-moodboard
                          :button="item.button"
                          :total="item.total"
                          :created="item.created"
                          :updated="item.updated"
                          :thumbs="item.thumbs"
                          :product-slug="$attrs.slug"
                          :img-id="$attrs['img-id']"
                          :moodboard-slug="item.slug.toString()"
                          :sizes="{ base: 200 }"
                          class="w-full"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <menu-user
                  v-else
                  class="w-full"
                  :on-signin-success="userStore.validate"
                />
              </component>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import { useUserStore } from "@/stores/user";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

const userStore = useUserStore();

const attrs = useAttrs();

const route = useRoute();
const isOpen = ref(false);
const tpl_collapse = templateRef("tpl_collapse");

function open() {
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

watch(
  () => userStore.logged,
  (logged) => {
    if (logged) {
      close();
    }
  }
);

watch(route, close);

function addItemOnMoodboardCreate(response) {
  //
  if (response.status == "success" && (attrs.slug || attrs.imgId)) {
    const data = {
      moodboard_slug: response.user.moodboards[0].slug,
    };

    if (attrs.slug) {
      data.product_slug = attrs.slug;
    } else if (attrs.imgId) {
      data.img_id = attrs.imgId;
      data.href =
        "/?redirect=" +
        route.path.replace(
          new RegExp(`/${route.params.country}/${route.params.locale}/`),
          ""
        );
    }

    userStore.addToMoodboard(data);

    if (tpl_collapse.value.$el.hasAttribute("open")) {
      tpl_collapse.value.$el.removeAttribute("open");
    }
  }
}

defineExpose({
  open,
});
</script>
