<template>
  <div component-name="card-add-to-moodboard" class="flex items-stretch gap-md">
    <div class="flex items-stretch gap-0.5 w-5/12">
      <wrapper-reveal
        reveal="unveil-from-right"
        class="w-3/4 h-full bg-skin-subtle"
      >
        <div
          class="flex items-center justify-center w-full overflow-hidden aspect-1/1"
        >
          <media
            v-if="thumbs && thumbs.length && thumbs[0]"
            v-bind="thumbs[0]"
            :sizes="{
              base: sizes.base * 0.75,
              phablet: sizes.phablet * 0.75,
              tablet: sizes.tablet * 0.75,
              laptop: sizes.laptop * 0.75,
              desktop: sizes.desktop * 0.75,
              extdesktop: sizes.extdesktop * 0.75,
            }"
            :loading="loading"
            aspect-ratio="none"
            :use-bg="false"
            class="w-full h-full max-h-full min-h-full"
          />
        </div>
      </wrapper-reveal>

      <div class="relative top-0 left-0 h-full w-4/12">
        <div
          class="absolute inset-0 flex flex-col items-stretch gap-0.5 w-full h-full max-h-full"
        >
          <wrapper-reveal
            reveal="unveil-from-left"
            class="w-full bg-skin-subtle h-1/2"
          >
            <div
              class="flex items-center justify-center w-full h-full max-h-full overflow-hidden"
            >
              <media
                v-if="thumbs && thumbs.length && thumbs[1]"
                v-bind="thumbs[1]"
                :sizes="{
                  base: sizes.base * 0.25,
                  phablet: sizes.phablet * 0.25,
                  tablet: sizes.tablet * 0.25,
                  laptop: sizes.laptop * 0.25,
                  desktop: sizes.desktop * 0.25,
                  extdesktop: sizes.extdesktop * 0.25,
                }"
                :loading="loading"
                aspect-ratio="none"
                :use-bg="false"
                class="w-full h-full max-h-full min-h-full"
              />
            </div>
          </wrapper-reveal>

          <wrapper-reveal
            reveal="unveil-from-left"
            class="w-full bg-skin-subtle h-1/2"
          >
            <div
              class="flex items-center justify-center w-full h-full max-h-full overflow-hidden"
            >
              <media
                v-if="thumbs && thumbs.length && thumbs[2]"
                v-bind="thumbs[2]"
                :sizes="{
                  base: sizes.base * 0.25,
                  phablet: sizes.phablet * 0.25,
                  tablet: sizes.tablet * 0.25,
                  laptop: sizes.laptop * 0.25,
                  desktop: sizes.desktop * 0.25,
                  extdesktop: sizes.extdesktop * 0.25,
                }"
                :loading="loading"
                aspect-ratio="none"
                :use-bg="false"
                class="w-full h-full max-h-full min-h-full"
              />
            </div>
          </wrapper-reveal>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-between items-stretch w-7/12 gap-xs">
      <div class="flex flex-col items-stretch w-full gap-xs">
        <wrapper-split-text reveal="chars" class="w-full">
          <btn-nav
            size="big"
            :title="button.title"
            :action="{ href: `${localizedBasePath}${button.action.to}` }"
          />
        </wrapper-split-text>

        <wrapper-reveal reveal="fade-from-right" class="w-full">
          <txt-moodboard-date
            :wrap="true"
            :total="total"
            :created="created"
            :updated="updated"
          />
        </wrapper-reveal>
      </div>

      <wrapper-reveal reveal="fade-from-bottom" tag="footer" class="w-full">
        <transition
          mode="out-in"
          leave-active-class="transition-opacity ease-in-out !pointer-events-none duration-200"
          leave-from-class="opacity-100"
          leave-to-class="!opacity-0"
          enter-active-class="transition-reveal ease-out origin-center !pointer-events-none duration-300"
          enter-from-class="scale-50 opacity-0"
          enter-to-class="!scale-100 !opacity-100"
        >
          <div
            v-if="alreadyAdded"
            key="added"
            class="flex items-center justify-center rounded-full bg-skin-muted text-skin-muted max-w-min p-sm"
          >
            <svg-icon icon="success" size="regular" />
          </div>

          <btn-pill
            v-else
            key="cta"
            size="small"
            :title="$t.labels.add"
            icon="plus"
            :disabled="adding || alreadyAdded"
            @click="adding || alreadyAdded ? () => {} : handleAdd()"
          />
        </transition>
      </wrapper-reveal>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";

const props = defineProps({
  button: {
    type: Object,
    required: true,
  },
  thumbs: {
    type: Array,
    required: false,
  },
  total: {
    type: Number,
    required: true,
  },
  created: {
    type: Number,
    required: true,
  },
  updated: {
    type: Number,
    required: true,
  },
  sizes: {
    type: Object,
    required: true,
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  imgId: {
    type: [String, Number],
    required: false,
  },
  productSlug: {
    type: String,
    required: false,
  },
  moodboardSlug: {
    type: String,
    required: true,
  },
});

const alreadyAdded = computed(() => {
  const moodboard = userStore.getMoodboard(props.moodboardSlug);
  if (!moodboard) {
    return false;
  }
  const product = moodboard.slugs.find((slug) => slug == props.productSlug);
  const media = moodboard.img_ids?.find((img_id) => img_id == props.imgId);
  return !!product || !!media;
});

const userStore = useUserStore();
const nuxtApp = useNuxtApp();
const adding = ref(false);
const route = useRoute();
const url = useRequestURL();

const localizedBasePath = computed(
  () => `${url.origin}/${route.params.country}/${route.params.locale}`
);

function handleAdd() {
  if (!userStore.logged) {
    return;
  }

  adding.value = true;

  const data = {
    moodboard_slug: props.moodboardSlug,
  };

  if (props.productSlug) {
    data.product_slug = props.productSlug;
  } else {
    data.img_id = props.imgId;
    data.href =
      "/?redirect=" +
      route.path.replace(
        new RegExp(`/${route.params.country}/${route.params.locale}/`),
        ""
      );
  }

  return userStore
    .addToMoodboard(data)
    .then((response) => {
      if (!response) {
        console.log(nuxtApp.$t.labels.something_went_wrong);
      }
      const { status, error } = response;

      if (status == "error" || error) {
        console.log(nuxtApp.$t.labels.something_went_wrong);
      }

      adding.value = false;

      return response;
    })
    .catch((error) => {
      console.log("error", error);
      console.log(nuxtApp.$t.labels.something_went_wrong);
    });
}
</script>
