<template>
  <div component-name="btn-add-to-moodboard">
    <btn-icon
      :title="$t.labels.add_to_moodboard"
      icon="add-to-moodboard"
      variant="rounded-filled"
      :size="$attrs.size"
      @click.stop.prevent="tpl_modal.open()"
    />

    <modal-add-to-moodboard ref="tpl_modal" :slug="slug" :img-id="imgId" />
  </div>
</template>

<script setup>
const tpl_modal = templateRef("tpl_modal");

const props = defineProps({
  slug: {
    type: String,
    required: false,
  },
  imgId: {
    type: [String, Number],
    required: false,
  },
});
</script>
